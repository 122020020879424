import gql from 'graphql-tag';

const mutation = {
  doctorLogin: gql`
    mutation doctorLogin($input: doctorLoginInput!) {
      doctorLogin(input: $input) {
        code
        success
        message
        token
        refresh_token
        token_expires
        refresh_token_expires
        otpHash
        verification_code
        doctor {
          id
          status
        }
      }
    }
  `,
  doctorLogInOtp: gql`
    mutation doctorLogInOtp($input: doctorLogInOtpInput!) {
      doctorLogInOtp(input: $input) {
        code
        success
        message
        token
        refresh_token
        token_expires
        refresh_token_expires
        doctor {
          id
          status
        }
      }
    }
  `,
  doctorSignUp: gql`
    mutation doctorSignUp($input: doctorSignUpInput!) {
      doctorSignUp(input: $input) {
        code
        success
        message
        token
        refresh_token
        token_expires
        refresh_token_expires
        doctor {
          id
          status
        }
      }
    }
  `,
  doctorUpdateProfile: gql`
    mutation doctorUpdateProfile($input: doctorUpdateProfileInput!) {
      doctorUpdateProfile(input: $input) {
        code
        success
        message
        doctor {
          id
          email
          timezone
          contact_no
          profile {
            doctor_id
            first_name
            last_name
            date_of_birth
            street
            city
            state
            postcode
            country
            profile_pic
            description
            provider_no
            prescriber_no
            onboarding_step
          }
          phone_verification
          email_verification
          status
        }
      }
    }
  `,
  sendOtp: gql`
    mutation sendOtp($phone: String!) {
      sendOtp(phone: $phone) {
        code
        success
        message
        fullHash
        verification_code
      }
    }
  `,
  verifyOtp: gql`
    mutation verifyOtp($input: verifyOtpInput!) {
      verifyOtp(input: $input) {
        code
        success
        message
      }
    }
  `,
  doctorUpdateSchedule: gql`
    mutation doctorUpdateSchedule($input: doctorUpdateScheduleInput!) {
      doctorUpdateSchedule(input: $input) {
        code
        success
        message
        schedules {
          start_at
          end_at
        }
      }
    }
  `,
  doctorDeleteSchedule: gql`
    mutation doctorDeleteSchedule($id: String!) {
      doctorDeleteSchedule(id: $id) {
        code
        success
        message
      }
    }
  `,
  consultationCallStart: gql`
    mutation consultationCallStart($id: String!) {
      consultationCallStart(id: $id) {
        code
        success
        message
        token
        conversation_id
      }
    }
  `,
  consultationCallEnd: gql`
    mutation consultationCallEnd($id: String!) {
      consultationCallEnd(id: $id) {
        code
        success
        message
      }
    }
  `,
  consultationPrescribe: gql`
    mutation consultationPrescribe($input: consultationPrescribeInput!) {
      consultationPrescribe(input: $input) {
        code
        success
        message
      }
    }
  `,
  consultationFinish: gql`
    mutation consultationFinish($id: String!) {
      consultationFinish(id: $id) {
        code
        success
        message
      }
    }
  `,
  consultationCallFinish: gql`
    mutation consultationCallFinish($id: String!, $input: CallFinishInput!) {
      consultationCallFinish(id: $id, input: $input) {
        code
        success
        message
      }
    }
  `,
  consultationApproval: gql`
    mutation consultationApproval($input: consultationApprovalInput!) {
      consultationApproval(input: $input) {
        code
        success
        message
      }
    }
  `,
  changePassword: gql`
    mutation changePassword($input: ChangePasswordInput!) {
      changePassword(input: $input) {
        code
        success
        message
      }
    }
  `,
  doctorRequestResetPassword: gql`
    mutation doctorRequestResetPassword($input: doctorRequestResetPasswordInput!) {
      doctorRequestResetPassword(input: $input) {
        code
        success
        message
      }
    }
  `,
  doctorResetPassword: gql`
    mutation doctorResetPassword($input: doctorResetPasswordInput!) {
      doctorResetPassword(input: $input) {
        code
        success
        message
        token
        refresh_token
        token_expires
        refresh_token_expires
        doctor {
          id
          status
        }
      }
    }
  `,
  patientVoidEscript: gql`
    mutation patientVoidEscript($id: String!) {
      patientVoidEscript(id: $id) {
        code
        success
        message
      }
    }
  `,
  patientGenerateEscript: gql`
    mutation patientGenerateEscript($input: patientGenerateEscriptInput!) {
      patientGenerateEscript(input: $input) {
        code
        success
        message
      }
    }
  `,
  consultationUploadDocuments: gql`
    mutation consultationUploadDocuments($id: String!, $input: uploadInput!) {
      consultationUploadDocuments(id: $id, input: $input) {
        code
        success
        message
      }
    }
  `,
  patientUploadDocuments: gql`
    mutation patientUploadDocuments($input: patientUploadDocumentsInput) {
      patientUploadDocuments(input: $input) {
        code
        success
        message
      }
    }
  `,
  consultationGenerateEscript: gql`
    mutation consultationGenerateEscript($id: String!) {
      consultationGenerateEscript(id: $id) {
        code
        success
        message
      }
    }
  `,
  consultationPrescribeEscripts: gql`
    mutation consultationPrescribeEscripts($input: consultationPrescribeEscriptsInput!) {
      consultationPrescribeEscripts(input: $input) {
        code
        success
        message
      }
    }
  `,
  patientSetDispensingLimits: gql`
    mutation patientSetDispensingLimits($input: patientSetDispensingLimitsInput!) {
      patientSetDispensingLimits(input: $input) {
        code
        success
        message
      }
    }
  `,
  doctorAddSchedule: gql`
    mutation doctorAddSchedule($input: DoctorAddScheduleInput!) {
      doctorAddSchedule(input: $input) {
        code
        success
        message
      }
    }
  `,
  doctorReadNotification: gql`
    mutation doctorReadNotification($id: String!) {
      doctorReadNotification(id: $id) {
        code
        success
        message
      }
    }
  `,
  patientSetDispensingInterval: gql`
    mutation patientSetDispensingInterval($input: patientSetDispensingIntervalInput!) {
      patientSetDispensingInterval(input: $input) {
        code
        message
        success
      }
    }
  `,
  patientUpdateProfile: gql`
    mutation patientUpdateProfile($id: String, $input: patientUpdateProfileInput!) {
      patientUpdateProfile(id: $id, input: $input) {
        code
        message
        success
        patient {
          id
          email
          timezone
          contact_no
          profile {
            patient_id
            first_name
            last_name
            pronoun
            gender
            date_of_birth
            street
            city
            state
            postcode
            country
            medicare_no
            profile_pic
            is_australian_resident
            is_over_18
            tried_conventional_treatment
            medical_symptoms
            medical_conditions
            conditions
            other_conditions
          }
          background {
            patient_id
            cannabis_used
            have_medical_prescription
            cannabis_history
            has_allergy
            allergy
            allergy_severity
            has_surgery
            surgery
            surgery_date
            alcohol_used
            tobacco_used
            exescise_activity
            family_history
            other_family_history
            medical_history
            other_medical_history
            currently_being_treated
            current_clinic
            current_doctor
            current_date
            currently_taking_medication
            current_medication
            current_medication_dose
            current_work_status
            current_work
          }
          phone_verification
          email_verification
          status
          billing_address {
            street
            city
            state
            postcode
          }
          delivery_address {
            street
            city
            state
            postcode
          }
          consultation_status
          last_consultation_date
          consent_approved
          same_delivery
          same_billing
          dva_no
          ihi_no
          ihi_status
          ihi_record_status
        }
      }
    }
  `,
  patientUpdatePinnedAlert: gql`
    mutation patientUpdateProfile($id: String, $input: patientUpdateProfileInput!) {
      patientUpdateProfile(id: $id, input: $input) {
        code
        success
        message
      }
    }
  `,
  consultationUpdateNotes: gql`
    mutation consultationUpdateNotes($id: String!, $treatment_notes: String!) {
      consultationUpdateNotes(id: $id, treatment_notes: $treatment_notes) {
        code
        message
        success
      }
    }
  `,
  consultationAddNotes: gql`
    mutation consultationAddNotes($id: String!, $notes: String!) {
      consultationAddNotes(id: $id, notes: $notes) {
        code
        message
        success
      }
    }
  `,
  consultationCallLog: gql`
    mutation consultationCallLog($input: consultationCallLogInput!) {
      consultationCallLog(input: $input) {
        code
        message
        success
      }
    }
  `,
  consultationComplete: gql`
    mutation consultationComplete($input: consultationCompleteInput!) {
      consultationComplete(input: $input) {
        code
        message
        success
      }
    }
  `,
  doctorAddPatientNote: gql`
    mutation doctorAddPatientNote($input: DoctorAddPatientNoteInput!) {
      doctorAddPatientNote(input: $input) {
        code
        message
        success
      }
    }
  `
};

export default mutation;
